 * {
     color: #A6AFC2;
     font-family: Poppins;
     font-weight: 100;
     font-size: 18px;
     line-height: 28px;
     letter-spacing: 0.03em;
 }
 body {
     margin: 0;
 }
 #root {
     width: 100vw;
     height: 100vh;
 }
 .pull-right {
     float: right;
 }
 .container {
     position: relative;
     width: 85vw;
     height: 100%;
     max-width: 1200px;
     margin: 0 auto;
     padding: 5em 0em;
     z-index: 2;
 }
 .App {
     width: 100vw;
     height: 100vh;
     margin: 0;
     position: fixed;
     background: radial-gradient(53.98% 62.68% at 71.25% 65.14%, rgba(30, 34, 45, 0) 0%, #000000 100%), radial-gradient(93.88% 74.56% at 74.13% 74.56%, #1E222D 0%, #15171c 100%);
 }
 header {
     width: 100%;
     display: table;
 }
 .logo-header {
     height: 24px;
     background-image: url('../img/logo.svg');
     background-size: contain;
     background-repeat: no-repeat;
     width: 179.13px;
     display: table-cell;
     vertical-align: middle;
     /* Mobile: 16px */
 }
 .preview-graphic {}
 canvas {
     width: 100%;
     height: 100%;
     outline: none;
 }
 #container {
     width: 100%;
     height: 100%;
     position: relative;
 }
 a {
     font-family: Poppins;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 28px;
     text-decoration: none;
     letter-spacing: 0.03em;
     color: #A6AFC2;
 }
 .gradient {
     background: linear-gradient(109.92deg, #00FF9F 1.12%, #00EFFF 91.34%);
 }
 .logo-header {
     height: 24px;
     background-position: center;
     /* Mobile: 16px */
 }
 button {
    cursor:pointer;
     border: 0;
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 16px;
     background: linear-gradient(109.92deg, #00FF9F 1.12%, #00EFFF 91.34%);
     border-radius: 16px;
     font-family: Poppins;
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 28px;
     text-align: center;
     color: #000000;
 }
 input {
     padding: 16px;
     filter: drop-shadow(0px 24px 64px #000000);
     background: linear-gradient(157.41deg, #16181F 0.89%, #0E1016 99.25%), linear-gradient(162.34deg, #13151A 18.52%, #0E1016 107.02%);
     border: 0;
     font-weight:400;
     color:white;
     box-sizing: border-box;
     box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 16px
 }
 input:focus, input:focus-visible, input:focus-within, input:-internal-autofill-selected { 
    color:white;
    outline:none;
    border:none;
        background-color: #101219;

     background: linear-gradient(157.41deg, #16181F 0.89%, #0E1016 99.25%), linear-gradient(162.34deg, #13151A 18.52%, #0E1016 107.02%);
 }
 h1 {
    font-weight: bold;
    color: white;
    font-size: 2em;
 }
 .cta .form-group {
     background-color: #101219;
     background: linear-gradient(157.41deg, #16181F 0.89%, #0E1016 99.25%), linear-gradient(162.34deg, #13151A 18.52%, #0E1016 107.02%);
     border: 1px solid #252937;
     width: 100%;
     border-radius: 16px;
     position: relative;
 }
 .main {
     height: 100%;
     display: flex;
     width: 100%;
     align-items: center;
     justify-content: left;
 }
 .cta {
     height: fit-content;
     width: 50%;
     max-width: 420px;
     position: fixed;
     top: 30%;
 }
 .cta .form-group button {
     display: inline-block;
     position: absolute;
     right: 0;
 }
 .form-group input, .form-group button {
     display: inline-block;
 }
 input::placeholder {
     opacity: 0.5;
     color: #A6AFC2;
     ;
 }
 .app-store-links {
     display: inline-block;
 }
 .app-store-links ul {
     margin: 0;
     padding: 0;
 }
 .app-store-links ul li {
     width: 60px;
     height: 60px;
     background-repeat: no-repeat;
     height: 60px;
     background-size: 24px;
     border: 1px solid rgba(166, 175, 194, 0.2);
     background-position: center;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     border-radius: 16px;
     display: inline-block;
 }
 .cta-copy .headline {
     background-image: url('../img/slogan-left.svg');
     background-repeat: no-repeat;
     height: 183px;
     width: 370px;
     background-position: left;
     background-size: contain;
 }
 .cta-copy p {
     margin-top: 2em;
     margin-bottom: 2em;
 }
 .app-store-links {
     display: table-cell;
     vertical-align: middle;
     cursor: pointer;
 }
 .app-store-links li a {
     width: 100%;
     height: 100%;
 }
 .google-play {
     background-image: url('../img/google-play.svg')
 }
 .apple {
     background-image: url('../img/apple.svg');
     margin-left: 1em;
 }
 .background-layer {
     width: 100%;
     height: 100%;
     position: fixed;
     z-index: 1;
     top: 0;
     left: 0;
 }
 .background-layer .overlay {
     position: absolute;
     top: 0;
     left: 0;
     background-image: url('../img/diffuse-green.png');
     width: 100%;
     height: 100%;
     z-index: 2;
     background-size: cover;
     background-position: bottom right;
 }
 .footer {
     position: absolute;
     left: 0;
     width: 100%;
     bottom:24vh;
 }
 .footer ul {
     margin: 0;
     padding: 0;
 }
 .footer ul li {
     display: inline-block;
     padding-right: 3em;
 }
 .footer ul li:last-of-type {
    padding-right:0em;
 }
 @media only screen and (orientation: portrait) { 
    .container {
       padding: 2em 0em;

    }
.footer {
   position: absolute;
    left: 0;
    width: 100%;
    bottom: 6em;
 padding: 0em;
     text-align:center;
    
} .footer ul li {
     display: inline-block;
     padding: 0em 3vw;
     text-align:center;
 }
.cta {
   max-width:none;
     width: fit-content;
    position: absolute;
    top: 24vh;
    margin:0 auto;
    text-align:center;
}
.cta-copy .headline {
      width:100%;
   background-image: url('../img/slogan-center.svg');
   background-position:center;
}
.cta-copy p {
   text-align:center;
}
.cta .form-group {
   background-color:transparent;
   background:transparent;
}
.cta button {
    margin-top:1em
}
.cta input, .cta button  {
   width:100%;
   text-align:center;
   display:block;
  
}

}

@media only screen and (max-width: 480px) {
     .app-store-links ul li {   width: 50px;
    height: 50px;
    
}
.apple {
    margin-left:0.6em;
}
}